import Carousel from 'react-bootstrap/Carousel';
import { ImageStream } from './ImageStream';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box, Typography } from '@mui/material';
import { slideshowSpeedRate } from '../config';
import AutoResizeText from './AutoresizeText';

export const SlideShow = ({
  images,
  isDarkMode = false,
  index = 0,
  setIndex = () => null,
  windowSize,
  styles
}) => {
  const interval = images?.length ? slideshowSpeedRate : null
  // const titleSize = (windowSize.width <= 1725 || windowSize.height <= 850) ? '2em' : '3em';
  // const descriptionFontSize = windowSize.width <= 1000 ? '1.3em' : (windowSize.width <= 1725 || windowSize.height <= 850) ? '1.5em' : '2em';
  return (
    <Carousel interval={interval} pause={false} indicators={false} controls={false} onSlide={setIndex}>
      {images.filter(item => !!item).map((item, index) => {
        if (!item) return <></>
        const { description, title } = item?.metadata || {}
        return (
          <Carousel.Item key={index} style={styles.carouselItem}>
            <Box style={styles.carouselItemContent}>
              <ImageStream id={item._id} styles={isDarkMode ? { filter: "brightness(0.7)" } : {}} />
              <AutoResizeText
                text={title}
                width="75%"
                height="10vh"
                lineHeight={1}
                fontWeight="normal"
                textAlign="center"
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="absolute"
                bottom="0px"
                maxFontSize={50}
                minFontSize={20}
              />
            </Box>
          </Carousel.Item>
        )
      })}
    </Carousel>
  );
}
