import { useMediaQuery } from '@mui/material';
import { fontFamily } from '@mui/system';

export const useMonitorStyles = (languageIndex, isDarkMode = false) => {
  const XXL = useMediaQuery('(min-width:3000px)');
  const XL = useMediaQuery('(min-width:2300px)');
  const L = useMediaQuery('(min-width:1921px)');
  const MD = useMediaQuery('(min-width:1720px)');
  const SM = useMediaQuery('(min-width:1550px)');
  const XS = useMediaQuery('(min-width:1000px)');
  const isEnglish = languageIndex === 0;
  const isHebrew = languageIndex === 1;
  const isArabic = languageIndex === 2;


  return {
    smallLogoContainer: {
      width: "unset",
    },
    smallLogoText: {
      fontFamily: "Regular, sans-serif",
      fontWeight: 600,
      fontSize: XXL ? (isEnglish ? '5em' : '6em') :
        XL ? (isEnglish ? '4em' : '4.5em') :
          L ? (isEnglish ? '3.5em' : '4em') :
            MD ? (isEnglish ? '3em' : '3.5em') :
              XS ? (isEnglish ? '2.5em' : '3.2em') : '3em'
    },
    smallLogoImage: {
      width: XXL ? '5em'
        : XL ? '4em'
          : L ? '3.5em'
            : '3em',
      height: XXL ? '5em'
        : XL ? '4em'
          : L ? '3.5em'
            : '3em'
    },
    screenNumber: {
      fontFamily: 'Regular, sans-serif',
      fontWeight: 600,
      paddingInlineEnd: 1,
      fontSize: XXL ? (isEnglish ? '5em' : '5em') :
        XL ? (isEnglish ? '3.5em' : '3.8em') :
          L ? (isEnglish ? '3em' : '3em') :
            '2.5em'
    },
    title: {
      fontFamily: 'Regular, sans-serif',
      textAlign: 'center',
      fontWeight: 600,
      fontSize: XXL ? (isEnglish ? '6.5em' : '8em')
        : XL ? (isEnglish ? '4.5em' : '6em')
          : MD || SM ? (isEnglish ? '4em' : '5em')
            : XS ? (isEnglish ? '3.5em' : '4em')
              : (isEnglish ? '4em' : '5em')
    },
    greetingHeader: {
      fontWeight: 'bold',
      fontSize: XXL ? (isEnglish ? '4em' : '4.5em')
        : XL ? (isEnglish ? '3em' : '3.2em')
          : L ? (isEnglish ? '2.5em' : '3em')
            : MD || SM ? (isEnglish ? '2.5em' : '3em')
              : XS ? (isEnglish ? '2em' : '2.5em')
                : (isEnglish ? '1.7em' : '2.5em')
    },
    greetingBody: {
      lineHeight: XXL ? (isEnglish ? 1.1 : 1)
        : XL ? (isEnglish ? 1 : 1)
          : L ? (isEnglish ? 1 : 1)
            : MD ? 1
              : SM ? 1
                : XS ? 1
                  : 1.5,
      fontSize: XXL ? (isEnglish ? '3.3em' : '3.6em')
        : XL ? (isEnglish ? '2.5em'
          : isArabic ? '2.7em'
            : '2.7em')
          : L ? (isEnglish ? '2.2em'
            : isArabic ? '2.4em'
              : '2.4em')
            : MD ? (isEnglish ? '2.1em'
              : isArabic ? '2.3em'
                : '2.2em')
              : SM ? (isEnglish ? '1.8em'
                : isArabic ? '2em'
                  : '1.9em')
                : XS ? (isEnglish ? '1.6em'
                  : isArabic ? '1.8em'
                    : '1.7em')
                  : '2.5em'
    },
    barCodeContainer: {
      position: "absolute",
      bottom: "calc(23vh - 21.5%)",
      width: "41vw",
      height: "23vh",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    qrWelcomeContainer: {
      aspectRatio: "1",
      height: "100%",
      overflow: "hidden",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // marginTop: XXL ? "-35px"
      //   : XL ? "-25px"
      //     : L ? "-20px"
      //       : MD ? "-15px"
      //         : SM ? "-5px"
      //           : XS ? "-10px"
      //             : "0px"
    },
    barcodeImage: {
      width: "100%",
      height: "calc(100% + 4vh)",
      // width: XXL ? "400px"
      //   : XS ? "300px"
      //     : "300px",
      // height: XXL ? "450px"
      //   : XL ? "340px"
      //     : L ? "310px"
      //       : MD ? "290px"
      //         : SM ? "260px"
      //           : XS ? "230px"
      //             : "200px",
      objectFit: "cover",
      backgroundColor: "black", // Removes white edges if they exist outside the QR
    },
    barcodeText: {
      width: "68%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingInline: "5%",
      fontSize: XXL ? (isEnglish ? '5em' : '5.5em')
        : XL ? '4em'
          : L ? '3.5em'
            : MD ? '3em'
              : SM ? '2.5em'
                : '2.5em'
    },
    //with posts
    slideshowContainer: {
      height: "calc(100vh - 13.5%)",
    },
    patientInfoContainer: {
      paddingTop: XXL ? (isEnglish ? 9 : 7)
        : XL ? (isEnglish ? 5 : 5)
          : L ? (isEnglish ? 4 : 5)
            : MD ? (isEnglish ? 4 : 5)
              : SM ? (isEnglish ? 3.5 : 4)
                : XS ? (isEnglish ? 3.5 : 4)
                  : 3,
      paddingInlineStart: XXL ? "200px"
        : XL ? "135px"
          : L ? "120px"
            : MD ? "110px"
              : SM ? "95px"
                : XS ? "85px"
                  : "80px",
      paddingInlineEnd: XXL ? "40px"
        : XL ? "50px"
          : L ? "38px"
            : MD ? "30px"
              : SM ? "20px"
                : XS ? "20px"
                  : "20px",
      width: XXL ? "41.5%"
        : XL ? "50.5%"
          : L ? "49.5%"
            : MD ? "48.5%"
              : SM ? "46.5%"
                : XS ? "46.5%"
                  : "46.5%",
    },
    patientInfoText: {
      fontFamily: 'Regular, sans-serif',
      fontWeight: 600,
      fontSize: XXL ? (isEnglish ? '3em' : '3em')
        : XL ? (isEnglish ? '2.2em'
          : isArabic ? '2.2em'
            : '2.2em')
          : L ? (isEnglish ? '2em'
            : isArabic ? '2em'
              : '2em')
            : MD ? "2em"
              : SM ? '1.8em'
                : XS ? '1.5em'
                  : '1.2em',
      marginInlineEnd: 2
    },
    patientHeader: {
      fontFamily: 'Regular, sans-serif',
      fontWeight: 600,
      fontSize: XXL ? (isEnglish ? '3.5em' : '3.8em')
        : XL ? (isEnglish ? '2.7em'
          : isArabic ? '2.8em'
            : '2.8em')
          : L ? (isEnglish ? '2.2em'
            : isArabic ? '2.8em'
              : '2.5em')
            : MD ? (isEnglish ? '2.1em'
              : isArabic ? '2.5em'
                : '2.2em')
              : SM ? (isEnglish ? '1.9em'
                : isArabic ? '2em'
                  : '2em')
                : XS ? (isEnglish ? '1.7em'
                  : isArabic ? '1.8em'
                    : '1.8em')
                  : '2.5em'
    },
    patientSubHeader: {
      fontFamily: 'Regular, sans-serif',
      fontSize: XXL ? (isEnglish ? '2.5em' : '3.5em')
        : XL ? (isEnglish ? '2em'
          : isArabic ? '2.2em'
            : '2em')
          : L ? (isEnglish ? '1.8em'
            : isArabic ? '2em'
              : '1.8em')
            : MD ? (isEnglish ? '1.7em'
              : isArabic ? '1.8em'
                : '1.7em')
              : SM ? (isEnglish ? '1.5em'
                : isArabic ? '1.6em'
                  : '1.5em')
                : XS ? (isEnglish ? '1.3em'
                  : isArabic ? '1.4em'
                    : '1.3em')
                  : '2em',
      lineHeight: 1.2
    },
    imageContainer: {
      height: "100%",
      marginTop: 2,
      paddingInlineEnd: XXL ? "50px"
        : XL ? "45px"
          : L ? "65px"
            : MD ? "40px"
              : SM ? "53px"
                : XS ? "44px"
                  : "40px",
      paddingInlineStart: XXL ? "99px"
        : XL ? "40px"
          : L ? "55px"
            : MD ? "50px"
              : SM ? "54px"
                : XS ? "48px"
                  : "45px",
      width: XXL ? "50%"
        : XL ? "58.4%"
          : L ? "61.4%"
            : MD ? "58.4%"
              : SM ? "58.4%"
                : XS ? "58.4%"
                  : "58.4%",
    },
    carouselItem: {
      height: XXL ? "calc(100vh - 270px)"
        : XL ? "calc(100vh - 200px)"
          : L ? "calc(100vh - 200px)"
            : MD ? "calc(100vh - 180px)"
              : SM ? "calc(100vh - 160px)"
                : XS ? "calc(100vh - 150px)"
                  : "calc(100vh - 150px)",
    },
    carouselItemContent: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      // paddingTop: XXL ? "200px"
      //   : XL ? "120px"
      //     : L ? "120px"
      //       : MD ? "120px"
      //         : SM ? "90px"
      //           : XS ? (isEnglish ? "120px" : "80px")
      //             : "80px",
      width: "100%",
      justifyContent: "center",
      alignItems: "center"
    },
    carouselDescription: {
      position: "absolute",
      bottom: "0px",
      // fontSize: XXL ? '3.5em'
      //   : XL ? '2.8em'
      //     : L ? '2.5em'
      //       : MD ? '2.5em'
      //         : SM ? '2.1em'
      //           : XS ? '2em'
      //             : '1.5em',
      fontWeight: 900,
      height: "10vh",
      width: "80%",
    },
    barCodeContainer2: {
      marginTop: 2,
      width: XXL ? "26%"
        : XL ? "30.5%"
          : L ? "29.5%"
            : MD ? "29.5%"
              : SM ? "27.5%"
                : XS ? "27.5%"
                  : "27.5%",
      paddingInlineStart: XXL ? "95px"
        : XL ? "55px"
          : L ? "23px"
            : MD ? "45px"
              : SM ? "21px"
                : XS ? "28px"
                  : "28px",
    },
    barCodeText2: {
      marginTop: XXL ? "130px"
        : XL ? "100px"
          : L ? "70px"
            : MD ? (isEnglish ? "50px" : isArabic ? "50px" : "60px")
              : SM ? "50px"
                : XS ? "50px"
                  : "50px",
      
      lineHeight: 1.3,
      maxWidth: "70%",
      textAlign: "center",
      fontWeight: 600
    },
    qrContainer: {
      width: "17vw",
      height: "17vw",
      overflow: "hidden",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    qrImage: {
      width: XXL ? "530px"
        : XL ? "400px"
          : L ? "348px"
            : MD ? "325px"
              : SM ? "300px"
                : XS ? "300px"
                  : "300px",
      height: XXL ? "615px"
        : XL ? "450px"
          : L ? "405px"
            : MD ? "380px"
              : SM ? "345px"
                : XS ? "310px"
                  : "300px",
      objectFit: "cover",
      backgroundColor: "black", // Removes white edges if they exist outside the QR
      marginTop: SM || XS ? 0 : 1
    },
    description: {
      fontSize: "20px",
      lineHeight: "1.2em",
      width: "500px",
      height: "120px",
      // paddingInlineStart: "5vw",
      // marginBottom: "-6vh",
      overflow: "hidden",
      padding: "1rem"
    }
  }
}; 