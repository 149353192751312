import { Grid, Typography } from '@mui/material'
import { ReactComponent as LogoSmall } from '../assets/subscriptions.svg' 
import { Trans } from '@lingui/macro'

export const SmallLogo = ({style = {}}) => (
    <Grid container direction="row" sx={style}>
        <Grid bottom={0}  marginInlineEnd={1} >
            <Typography fontFamily="Fredoka" variant='h6' fontWeight={700} fontSize="2.5em" >{<Trans>Empathy</Trans>}</Typography>
        </Grid>
        <Grid bottom={0} marginTop={1.5}>
            <LogoSmall width={"2.5em"} height={"2.5em"}/>
        </Grid>
    </Grid>
)

export const SmallLogo_V2 = ({styles = {}}) => (
    <Grid container direction="row" sx={styles.smallLogoContainer} alignItems="center" justifyContent="center">
        <Grid bottom={0}  marginInlineEnd={1} >
            <Typography fontFamily="Fredoka" variant='h6' sx={styles.smallLogoText} >{<Trans>Empathy</Trans>}</Typography>
        </Grid>
        <Grid bottom={0} marginTop={1.5}>
            <LogoSmall {...styles.smallLogoImage}/>
        </Grid>
    </Grid>
)