import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const TextContainer = styled(Box)(({ width, height }) => ({
  maxWidth: "100%",
  width: width || '100%',
  height: height || 'auto',
}));

const StyledTypography = styled(Typography)({
  whiteSpace: 'pre-line', // This enables new line support
  width: '100%',
  margin: 0,
  padding: 0,
});

const AutoResizeText = ({ 
  text, 
  width, 
  height,
  maxFontSize = 100, // in pixels
  minFontSize = 12,  // in pixels
  lineHeight = 1.2,
  fontFamily = 'Regular, sans-serif',
  color = 'inherit',
  fontWeight = 'normal',
  textAlign = '',
  languageIndex = '',
  ...props 
}) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const [fontSize, setFontSize] = useState(maxFontSize);

  const calculateIdealFontSize = () => {
    if (!containerRef.current || !textRef.current) return;

    const containerWidth = containerRef.current.offsetWidth;
    const containerHeight = containerRef.current.offsetHeight;

    let currentFontSize = maxFontSize;
    textRef.current.style.fontSize = `${currentFontSize}px`;

    let min = minFontSize;
    let max = maxFontSize;

    while (min <= max) {
      currentFontSize = Math.floor((min + max) / 2);
      textRef.current.style.fontSize = `${currentFontSize}px`;

      const textHeight = textRef.current.offsetHeight;
      const textWidth = textRef.current.offsetWidth
      if (textHeight <= containerHeight && textWidth <= containerWidth) {
        min = currentFontSize + 1;
      } else {
        max = currentFontSize - 1;
      }
    }

    setFontSize(Math.max(minFontSize, max - 1));
  };

  useEffect(() => {
    calculateIdealFontSize();
  }, [languageIndex]);

  useEffect(() => {
    calculateIdealFontSize();

    // Add resize observer to handle container size changes
    const resizeObserver = new ResizeObserver(() => {
      // Add small delay to ensure DOM is updated
      setTimeout(calculateIdealFontSize, 0);
    });
    
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect();
    };
  }, [text, width, height, maxFontSize, minFontSize]);

  return (
    <TextContainer
      ref={containerRef}
      width={width}
      height={height}
      {...props}
    >
      <StyledTypography
        ref={textRef}
        style={{
          fontSize: `${fontSize}px`,
          lineHeight,
          fontFamily,
          color,
          fontWeight,
          textAlign,
        }}
      >
        {text}
      </StyledTypography>
    </TextContainer>
  );
};

export default AutoResizeText;
