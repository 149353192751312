import React, { useEffect, useRef, useState } from 'react'
import { calculateMillisecondsUntil, getCookie, removeCookie, setCookie } from '../../helpers'
import { Box, CardMedia, Container, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { monitorActions } from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { SlideShow, SmallLogo_V2 as SmallLogo } from '../../components'
import { io } from 'socket.io-client'
import { languageInterval, serverUrl } from '../../config'
import FilterHdrIcon from '@mui/icons-material/FilterHdr';
import { Trans, t } from '@lingui/macro'
import { LANGUAGES } from '../../enums'
import { useLanguage } from '../../components/LanguageContext'
import backgroundImageSlideshow from '../../assets/images/BG_Screen2.jpg'
import live_bg from '../../assets/videos/live_photo.mp4'
import overlay_bg from '../../assets/images/BG_Screen1.png'
import { useMonitorStyles } from './useMonitorStyles'
import AutoResizeText from '../../components/AutoresizeText'

export const MonitorSlideShow = () => {
  const [socket, setSocket] = useState(null)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { changeLanguage } = useLanguage()
  const intervalRef = useRef(null)

  const [languageIndex, setLanguageIndex] = useState(0);
  const [imageIndex, setImageIndex] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const { deviceId, images = [], qr, patient, dayTime = "06", nightTime = "19", error } = useSelector(state => state.monitor)
  const { name = '', birthCountry = '', family = '', profession = '', hobbies = '', gossip = '', landmarks = '' } = patient || {}
  const isEmptyPatientInfo = !patient || (!name && !birthCountry && !family && !profession && !hobbies && !gossip && !landmarks)

  const languages = isEmptyPatientInfo ? ["he", "ar"] : ["en", "he", "ar"]
  const profile = [
    {
      label: t`I was born in`,
      value: birthCountry
    },
    {
      label: t`My family and roots are`,
      value: family
    },
    {
      label: t`I work in`,
      value: profession
    },
    {
      label: t`My hobbies are`,
      value: hobbies
    },
    {
      label: t`Something interesting about me is `,
      value: gossip
    },
    {
      label: t`I achieved in my life`,
      value: landmarks,
      labelCol: 12,
      valueCol: 12,
    },
  ]

  const styles = useMonitorStyles(languageIndex);

  useEffect(() => {
    if (!deviceId) return;

    const socketIO = io(serverUrl, {
      auth: {
        token: getCookie("token"),
        reconnection: true, // Whether to automatically attempt to reconnect
        reconnectionAttempts: 5000, // Number of reconnection attempts before giving up
        reconnectionDelay: 10000, // How long to initially wait before attempting a new reconnection
        reconnectionDelayMax: 15000,
      }
    })

    setSocket(socketIO)
  }, [deviceId])

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scheduleThemeToggle = (hour, minute, callback) => {
    const timeUntilNextTrigger = calculateMillisecondsUntil(hour, minute);
    const timeoutId = setTimeout(() => {
      callback();
      scheduleThemeToggle(hour, minute, callback);
    }, timeUntilNextTrigger);
    return timeoutId;
  };

  useEffect(() => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const dayVal = parseInt(dayTime)
    const nightVal = parseInt(nightTime)

    if (hours >= dayVal && hours < nightVal) {
      setIsDarkMode(false);
    } else {
      setIsDarkMode(true);
    }
    const nightTimeout = scheduleThemeToggle(nightVal, 0, () => setIsDarkMode(true));
    const dayTimeout = scheduleThemeToggle(dayVal, 4, () => setIsDarkMode(false));

    return () => {
      clearTimeout(nightTimeout);
      clearTimeout(dayTimeout);
    };
  }, [dayTime, nightTime]);

  useEffect(() => {
    setIsVisible(true);
  }, [languageIndex]);

  const languageTimeout = () => setTimeout(() => {
    const newIndex = (languageIndex + 1) % languages.length;
    const newLanguage = languages[newIndex];
    changeLanguage(newLanguage)
    setLanguageIndex(newIndex);
  }, 700)

  useEffect(() => {
    if (images.length > 1) return;

    const languageIntervalFunc = setInterval(() => {
      setIsVisible(false)
      languageTimeout()
    }, 10000);

    return () => {
      clearInterval(languageIntervalFunc)
      clearTimeout(languageTimeout)
    };
  }, [languageIndex]);

  useEffect(() => {
    if (imageIndex !== 0) return;
    setIsVisible(false)
    languageTimeout()

    return () => {
      clearTimeout(languageTimeout)
    }
  }, [imageIndex])

  const onSocketDisconnect = () => {
    removeCookie("token")
    navigate("/monitor")
  }

  const onConnected = () => {
    console.log("connected")
    if (!deviceId) return
    socket.emit("connected", deviceId)

    if (!!intervalRef.current)
      clearInterval(intervalRef.current)

    intervalRef.current = setInterval(() => {
      if (socket.connected) {
        socket.emit('ping', deviceId);
      } else {
        console.log('Attempting to reconnect...');
        socket.connect();
      }
    }, languageInterval)
  }

  const onDisconnect = (msg) => {
    // socket.connect()
  }

  const monitorUpdate = async (msg) => {
    if (msg?.event === "deleted") {
      console.log("monitor deleted, disconnecting", msg)
      socket.disconnect()
      onSocketDisconnect()
      return
    } else if (msg?.event === "cleared") {
      setCookie("token", msg?.data?.token)
    }

    await dispatch(monitorActions.getMonitor())
  }

  const onReload = () => {
    window.location.reload()
  }

  const connect = () => {
    socket.on("connect", onConnected)
    socket.on("monitorUpdate", monitorUpdate)
    socket.on("disconnect", onDisconnect)
    socket.on("reloadMonitor", onReload)
    socket.on("disconnectMonitor", () => {
      socket.disconnect()
      onSocketDisconnect()
    })
  }

  useEffect(() => {

    if (!getCookie('token')) navigate('/monitor')

    return () => {
      dispatch(monitorActions.clearMonitorState())
      if (!!intervalRef.current)
        clearInterval(intervalRef.current)
      if (!socket) return;
      socket.disconnect()
      socket.removeAllListeners()
    }
  }, [])

  useEffect(() => {
    console.log({ error })
    if (error) navigate('/monitor')
  }, [error])

  useEffect(() => {
    if (!getCookie('token') || error) navigate('/monitor')
    if (!deviceId) dispatch(monitorActions.getMonitor())
    if (deviceId && socket) connect()
  }, [deviceId, socket, dispatch, navigate])


  useEffect(() => {
    if (deviceId && !images.length) dispatch(monitorActions.getMonitor())
  }, [deviceId])

  const renderPatientInfo = () => {

    if (isEmptyPatientInfo) {
      return (
        <Grid container display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <AutoResizeText
                text={t`Meet the "Empathy" system!`}
                width="100%"
                height="10vh"
                lineHeight={1}
                fontWeight="bold"
                textAlign='center'
              />
          {/* <Typography textAlign="center" style={styles.title}>
            <Trans>Meet the "Empathy" system!</Trans>
          </Typography> */}

          <Box sx={{ width: "100%", marginTop: 5 }}>
            <Box sx={{ width: "100%", height: "50vh" }}>
              <AutoResizeText
                text={t`Dear patient, dear family`}
                languageIndex={languageIndex}
                width="100%"
                height="5vh"
                lineHeight={1}
                fontWeight="bold"
              />
              <AutoResizeText
                text={t`Meet the "Empathy" system – designed to help us, the care team, see you beyond your hospital pajamas.

                        In everyday reality, it's easy to forget that every patient is a whole person with better and worse moments. The system allows us to get to know you and see you as you really are or were – with family, at work, on a trip, in hobbies, in festive or sports clothes...

                        We would love for you to share with us a different personal perspective.

                        Thank you!

                        PS

                        Simply scan the QR, and in a moment the materials you choose will be displayed on this screen.`}
                marginTop={2}
                width="40vw"
                height="38vh"
                lineHeight={1}
                fontWeight="normal"
                languageIndex={languageIndex}
              />
            </Box>

            <Box sx={styles.barCodeContainer}>
              <Box sx={styles.qrWelcomeContainer}>
                <CardMedia
                  component="img"
                  image={qr}
                  alt="QR Code"
                  sx={styles.barcodeImage}
                />
              </Box>
              <AutoResizeText
                text={t`Scan the barcode to activate the system.`}
                width="68%"
                height="18vh"
                lineHeight={1}
                fontWeight="normal"
                paddingInline={"10%"}
                textAlign="center"
                display="flex"
                justifyContent="center"
                alignItems="center"

              />
            </Box>
          </Box>
        </Grid>
      );
    }

    return renderSlideShow();
  };

  const renderSlideShow = () => {
    return (
      <Box display={"flex"} flexDirection={"row"} sx={styles.slideshowContainer}>
        <Box sx={styles.patientInfoContainer}>
          {name && <>
            <Typography sx={styles.patientHeader} whiteSpace={"normal"} textAlign={"center"}>{t`Hello, I am ${name}`}</Typography>
            <Typography sx={styles.patientSubHeader} whiteSpace={"normal"} textAlign={"center"}>{t`Nice to meet you and thank you for the opportunity to present a taste of my life to you`}</Typography>
          </>
          }
          <Box mt={2}>
            {profile.map(({ value, label }) => {
              if (!value) return null
              return (
                <Typography key={label} sx={styles.patientInfoText} marginInlineEnd={2}>
                  {`${label} `}<Box display="inline" fontWeight={300}>{value}</Box>
                </Typography>
              )
            })}
          </Box>
        </Box>
        <Box sx={styles.imageContainer}>
          {images.length ? <SlideShow
            images={[...images]}
            isDarkMode={isDarkMode}
            index={imageIndex}
            setIndex={setImageIndex}
            windowSize={windowSize}
            styles={styles}
          /> : (
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
              <Box style={{ width: "600px", height: "500px", border: "15px solid gray", borderRadius: 20, backgroundColor: isDarkMode ? "#ccc" : "floralWhite" }}>
                <FilterHdrIcon style={{ width: "100%", height: "100%", color: isDarkMode ? "gray" : "darkcyan" }} />
              </Box>
            </Box>
          )}
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"28%"}
          height={"73vh"}
          top={"23vh"}
          paddingInlineStart={"95px"}
          sx={styles.barCodeContainer2}
        >
          <AutoResizeText
            text={t`Scan to upload additional content!`}
            width="100%"
            height="16vh"
            lineHeight={1}
            fontWeight="normal"
            paddingInline={"10%"}
            textAlign="center"
            display="flex"
            justifyContent="center"
            alignItems="center"
            maxWidth={"100%"}
            {...styles.barCodeText2}

          />
          <Box sx={styles.qrContainer}>
            <CardMedia
              component="img"
              image={qr}
              alt="QR Code"
              sx={styles.qrImage}
            />
          </Box>
        </Box>
      </Box>
    )
  }


  return (
    <Container
      style={{
        maxWidth: "100vw",
        height: "100vh",
        ...(!isEmptyPatientInfo && {
          backgroundImage: `url(${backgroundImageSlideshow})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          transform: document.documentElement.dir === 'ltr' ? 'scaleX(-1)' : 'none'
        })
      }}>
      {isEmptyPatientInfo && (
        <>
          < Box sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${overlay_bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: -1, // Ensures it stays behind the video
            transform: document.documentElement.dir === 'ltr' ? 'scaleX(-1)' : 'none'
          }} />
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -2, // Keeps it behind other content
              transform: document.documentElement.dir === 'ltr' ? 'scaleX(-1)' : 'none'
            }}
          >
            <source src={live_bg} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </>

      )}
      <Box className={`screen ${isVisible ? "visible" : ""}`} sx={{
        height: "100%", width: "100%",
        ...(!isEmptyPatientInfo && { transform: document.documentElement.dir === 'ltr' ? 'scaleX(-1)' : 'none' })
      }}>
        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} height={"11.5%"}>
          <Typography sx={styles.screenNumber}>{t`Screen number: ${deviceId}`}</Typography>
          <SmallLogo styles={styles} />
        </Box>
        {images.length ? renderSlideShow() : renderPatientInfo()}
      </Box>
    </Container>
  )
}